<template>
  <div :class="$style.root">
    <template v-if="product">
      <div :class="$style.header">
        <h3>お気に入りに追加する</h3>
      </div>
      <div :class="$style.product_title">
        <h4>{{ product.name }}</h4>
        <p>{{ product.material }}</p>
      </div>

      <ul :class="$style.list_box">
        <li v-for="colorToSku of colorToSKus" :key="colorToSku.color" :class="$style.list">
          <div :class="$style.top">
            <div :class="$style.img_box">
              <AtomsConsumerImage :src="colorToSku.image" />
            </div>
            <div v-if="colorToSku.color !== 'No Color'" :class="$style.color_label">
              <p>{{ `${selectedSkuColorLabel}：${colorToSku.color}` }} </p>
            </div>
          </div>
          <div :class="$style.bottom">
            <div v-for="sku of colorToSku.skus" :key="sku.document_id" :class="$style.fav_btn_box">
              <p>{{ sku.size }}</p>
              <AtomsConsumerButton @click="onFavorite(sku.document_id, product.document_id)">
                <AtomsConsumerImage :src="sku.isFavorite ? '/images/icon/fav-navy.svg' : '/images/icon/fav.svg'" />
              </AtomsConsumerButton>
            </div>
          </div>
        </li>
      </ul>
      <div :class="$style.close_btn_box">
        <AtomsConsumerButton variant="primary" block @click="() => emit('lift')"> 閉じる </AtomsConsumerButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { SelectFavProps_Product, SelectFavProps_ProductSku } from "~/types/product"
import type { FavoriteFragment } from "~/types/type.generated"

type Props = {
  product: SelectFavProps_Product | null
  favoriteList: FavoriteFragment[]
  isProcessing: boolean
}
type SkuWithFavStatus = SelectFavProps_ProductSku & {
  isFavorite: boolean
}
type ColorToSkusMap = {
  color: string
  image: string
  skus: SkuWithFavStatus[]
}

const props = withDefaults(defineProps<Props>(), {
  product: null,
  favoriteList: () => [],
  isProcessing: false,
})

const colorToSKus = computed<ColorToSkusMap[]>(() => {
  if (!props.product?.skus) return []
  const productMap = props.product.skus.reduce<Record<string, ColorToSkusMap>>((acc, sku) => {
    const color = sku.color || "No Color"
    const image = sku.main_image || ""
    if (!acc[color]) {
      acc[color] = {
        color,
        skus: [],
        image,
      }
    }
    const isFavorite = props.favoriteList.some((fav) => fav.sku_id === sku.document_id)
    const skuWithStatus = { ...sku, isFavorite }
    if (!acc[color].image && sku.main_image) {
      acc[color].image = sku.main_image
    }
    acc[color].skus.push(skuWithStatus)
    return acc
  }, {})
  return Object.values(productMap)
})

const emit = defineEmits<{
  (e: "onFavorite", skuId: string, productId: string): void
  (e: "lift"): void
}>()
const favSelectedSku = ref<SelectFavProps_ProductSku | null>(props.product?.skus[0] || null)
const selectedSkuColorLabel = computed(() => {
  if (favSelectedSku.value?.color_label) return favSelectedSku.value.color_label
  return "カラー"
})
const onFavorite = (skuId: string, productId: string) => {
  if (props.isProcessing) return
  emit("onFavorite", skuId, productId)
}
</script>

<style scoped module lang="scss">
.root {
  .header {
    font-size: $font-size-15;
    color: $primary;
    margin-top: 64px;
    padding-bottom: 12px;
    border-bottom: 1px solid $primary-20;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_title {
    margin-bottom: 0.875rem;
    h4 {
      font-size: 1.25rem;
      font-weight: bold;
    }
    p {
      font-size: 0.875rem;
    }
  }
  .list_box {
    list-style: none;
    .list {
      margin-bottom: 2rem;
      .top {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;
        .img_box {
          > img {
            width: 100px;
            height: auto;
            @include md {
              width: 150px;
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex-wrap: wrap;
        @include md {
          width: 500px;
        }
        .fav_btn_box {
          width: 48%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $black-20;
          padding: 0.5rem;
          &:first-child,
          &:nth-child(2) {
            border-top: 1px solid $black-20;
          }
          &:nth-child(odd) {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .size_box {
    margin-bottom: 0.875rem;
  }
}
</style>
